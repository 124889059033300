<template>
  <div class="main">
    <div class="top">
      <div style="float:left" @click="back"><img src="@/assets/return1.png" alt=""></div>
      <h5 class="title">“一刀智投”自动交易软件用户使用协议</h5>
    </div>
    <h3 class="second">用户使用协议</h3>
    <p>本协议是一刀智投的所有者北京爱玩特智能信息技术有限公司(以下简称“甲方”)与一刀智投用户(以下简称“乙方”)就付费服务的相关事项所订立的有效合约。乙方在订阅前请仔细阅读本协议的全部内容，一旦确认订阅或使用付费服务，即表示乙方与甲方已达成协议并接受本协议的全部约定内容。如乙方不接受本协议的任意内容，或者无法准确理解我司对条款的解释，请不要进行后续购买或者使用操作。</p>
    <div class="middle">
      <h1>一、服务内容和价格</h1>
      <p>1. 付费服务指甲方提供的各种有偿服务。本协议适用于甲方所有付费服务。</p>
      <p>2. 甲方的条件选股类服务指系统根据固定的选股条件，实时在甲方数据中心运算、监控的结果。其结果是模型基于历史数据的客观反映，不承诺收益，甲方不为模型计算结果的涨跌负责，也不为据此操作后的盈亏负责。您的使用行为将视为知晓本项条款、并同意接受本项条款的约束。</p>
      <p>3. 甲方的自动炒股机器人，指系统根据模型或人工智能算法，实时对证券投资品种的历史数据、最新数据，进行统计、汇总、计算并延伸出新的数据结果。乙方若认同策略模型，确认使用策略模型交易，为乙方自助行为。甲方不为模型计算结果的涨跌负责，也不为据此操作后的盈亏负责。您的使用行为将视为知晓本项条款、并同意接受本项条款的约束。</p>
      <p>4. 甲方提供“一刀智投”软件使用账号一个，期限一年，共本人单个用户使用。每套售价为：_____ 。</p>
      <h1>二、服务说明</h1>
      <p>1.乙方需提前了解付费服务的内容，一旦订阅或使用，即视为乙方已熟悉并了解所购服务的内容，需遵守本协议。</p>
      <p>2. 甲方为乙方提供的所有服务均由乙方通过爱玩特官网、APP及官方微信公众平台来接收和使用。</p>
      <p>3. 甲方所有付费服务支付成功后服务即时开通并生效，不支持中途暂停服务。</p>
      <p>4.付费服务期限届满后，乙方可向甲方申请下一年度的平台使用权，并支付一年度的费用，</p>
      <h1>三、风险提示</h1>
      <p>1.乙方应充分认识到证券投资的风险。乙方应在投资决策前做出理性判断，并自行承担投资风险。</p>
      <p>2.证券市场具有较强的风险，甲方及旗下产品作为投资辅助工具，无法规避市场系统性或非系统性风险。甲方及旗下产品服务不能保证您盈利，也不承诺您的投资不遭受损失，您应在投资决策前做出理性判断，并自行承担投资风险。</p>
      <p>3. 甲方及旗下产品涉及的所有行情、资讯、数据来自于沪深证券交易所及其他交易所或第三方资讯商。甲方会尽可能确保系统的稳定性，但无法保证数据、网络100%稳定和准确，由于行情、资讯、数据的不准确或遗漏而引起的任何损失，甲方不承担任何法律和经济责任。</p>
      <p>4. 甲方及旗下产品以及财产上的利害关系人与基于收费服务建议的证券没有任何利害关系。所有的评价分析仅根据市场公开信息，不存在任何利益关系，您应当理性看待收费功能，谨慎投资。</p>
      <p>5.可能存在第三方服务风险，如网络服务接入商、短信发送商、移动运营商的服务问题、技术问题、网关问题等造成您和网站访客接收网站信息延迟、错误、异常等，对此造成的第三方服务滞后风险您应充分知悉。</p>
      <h1>四、约束条款</h1>
      <p>1.购买的服务仅限于甲方开通的账号自行使用，乙方不得转移、借用、转让或售卖，不得以任何收费或免费的方式公开传播订阅内容。否则甲方有权在未通知的情况下取消付费服务资格，由此带来的损失由乙方自行承担，同时爱玩特保留法律诉讼权利。</p>
      <p>2.乙方不得以盗窃、利用系统漏洞等非法途径，以及在未获取甲方授权的非法平台上获取或开通付费服务，否则甲方有权取消其付费服务资格。</p>
      <p>3.因乙方自身原因违规操作（包括但不限于散布谣言、发布广告、恶意访问等）导致账号被封无法使用，甲方不予退还该乙方的付费服务费用。</p>
      <h1>五、免责条款</h1>
      <p>1. 甲方对因证券交易所、卫星传输线路、电信部门及网络服务提供商、上市公司信息提供商等任何原因造成的信息传递异常情况所致后果不承担任何连带法律责任。乙方应始终避免并消除由于上述原因造成的信息遗漏、错误、丢失、延迟、中断等可能给爱玩特带来不利影响的因素，使其免受经济损失和名誉损失。此外，由于地震、台风、战争、罢工、政府行为、瘟疫、爆发性和流行性传染病或其他重大疫情、各方原因造成的火灾、基础电信网络中断造成的及其它各方不能预见并且对其发生后果不能预防或避免的不可抗力原因，致使相关服务中断，甲方不承担由此产生的损失，但应及时通知服务中断原因，并积极加以解决。</p>
      <p>2.当政府司法机关依照法定程序要求本网站披露个人资料时，甲方将根据执法单位之要求或为公共安全之目的提供个人资料。在此情况下之任何披露，甲方均得免责。</p>
      <h1>六、服务内容的版权与知识产权</h1>
      <p>1. 甲方提供的网络信息服务内容，均受版权保护：为甲方版权所有。乙方不能擅自复制、随意发布相关服务内容；不得根据服务内容，改造或创造与内容有关的派生产品。不得将甲方提供的数据和信息内容向他人发布并收取不正当的费用，甲方保留一切追究任何侵权的权利。</p>
      <p>2. 甲方的一切著作权、商标权、专利权、商业秘密等知识产权，以及相关的所有信息内容均受中华人民共和国法律法规和相应国际条约的保护。</p>
      <p>3.乙方应按甲方所提供的技术要求和操作手册正常使用本软件，不得对本产品涉及的相关网页、应用、软件等产品进行反向工程、反向汇编、反向编译等。</p>
      <p>4.未经我公司或相关权利人授权，乙方不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。</p>
      <h1>七、关于中断或终止服务</h1>
      <p>1.因发生不可抗拒的事由，如政府行为、不可抗力，导致乙方服务无法继续提供，甲方将尽快通知乙方，但不承担由此对乙方造成的任何损失或退还任何已缴纳的收费服务费用。</p>
      <p>2.因甲方公司经营政策调整、付费服务下架等原因导致甲方无法继续提供付费服务的，甲方有权单方面终止提供付费服务或更换同等价值的其他付费服务，并应提前通知乙方。若乙方不同意更换服务，甲方将根据乙方的使用情况折合计算剩余服务金额，退款金额将在5个工作日内原路退还给乙方。</p>
      <h1>八、其他</h1>
      <p>1.在法律法规允许的范围内，本协议最终解释权归我公司所有。</p>
      <p>2.本协议的订立、执行、解释及争议的解决均应适用中国法律并受中国法院管辖。如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向我公司所在地的人民法院提起诉讼。</p>
      <p>3.如果本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
    </div>

  </div>
</template>

<script>
export default {
	methods: {
		back() {
			this.$router.go(-1)
		}
	}

}
</script>

<style scoped>
p{
    text-indent: 2em;
    margin: 0.5rem 0;
    font-size: 1rem;
    line-height: 25px;
}
h1{
    font-size: 18px;
    font-weight: 700;
}
.main{
    padding: 2.0vw
}
.top{
    width: 96vw;
    padding: 2.0vw;
    position: fixed;
    top: 0;
    left: 0;
    background: #f0f3f6;
}
.title,.second{
   text-align: center
}
.second{
    margin-top: 5vh;
    font-weight: 700;
    font-size: 20px;
}
</style>>

