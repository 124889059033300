import { render, staticRenderFns } from "./userservice.vue?vue&type=template&id=85256ddc&scoped=true&"
import script from "./userservice.vue?vue&type=script&lang=js&"
export * from "./userservice.vue?vue&type=script&lang=js&"
import style0 from "./userservice.vue?vue&type=style&index=0&id=85256ddc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85256ddc",
  null
  
)

export default component.exports